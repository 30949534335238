import Button, { ButtonSize, ButtonType } from '../shared/form-control/Button';
import { useTableView } from '../../contexts/table-view/TableViewContext';
import { mainViewId } from '../../models/TableView';
import { useTranslation } from 'react-i18next';
import { useTableViewScroll } from '../../contexts/table-view/TableViewScrollContext';
import { ChevronIcon, ChevronType } from '../shared/icon/ChevronIcon';

const TableViewActionButtons = () => {
  const { onEditTableView, onSaveTableView, onDiscardTableView, selectedTableView, isDirty, templateModuleSectionId } = useTableView();
  const { onScrollLeft, onScrollRight, canScrollLeft, canScrollRight } = useTableViewScroll();
  const { t } = useTranslation(['table-view']);

  const buttonClasses = 'flex items-center justify-center h-[30px] w-9 rounded-md bg-gray-6 text-gray-2 p-1 cursor-pointer hover:bg-gray-5';
  return (
    <div className="flex items-center gap-2">
      {selectedTableView?.id === mainViewId && templateModuleSectionId && isDirty && (
        <Button size={ButtonSize.S} type={ButtonType.SECONDARY} onClick={onSaveTableView}>
          {t('buttons.save-as-new')}
        </Button>
      )}
      {selectedTableView?.id !== mainViewId && templateModuleSectionId && (
        <>
          <Button size={ButtonSize.S} type={ButtonType.SECONDARY} onClick={onEditTableView}>
            {t('buttons.edit')}
          </Button>
          <Button size={ButtonSize.S} type={ButtonType.SECONDARY} onClick={onDiscardTableView} disabled={!isDirty}>
            {t('buttons.discard')}
          </Button>
          <Button size={ButtonSize.S} type={ButtonType.SECONDARY} onClick={onSaveTableView} disabled={!isDirty}>
            {t('buttons.save')}
          </Button>
        </>
      )}
      <ChevronIcon
        type={ChevronType.LEFT}
        className={`${buttonClasses} ${!canScrollLeft ? 'pointer-events-none opacity-50' : ''}`}
        onClick={onScrollLeft}
      />
      <ChevronIcon
        type={ChevronType.RIGHT}
        className={`${buttonClasses} ${!canScrollRight ? 'pointer-events-none opacity-50' : ''}`}
        onClick={onScrollRight}
      />
    </div>
  );
};

export default TableViewActionButtons;
