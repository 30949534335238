/* eslint-disable @typescript-eslint/no-explicit-any */
import { PeriodicReviewStatus } from './PeriodicReview';
import { DistributionStatusFilter } from './Distribution';
import {
  ColumnConfig,
  ColumnType,
  PlatformStatusToGQLStatus,
  graphQLMetadataFields,
  noDataId,
  tableViewMetadata,
  MetaClientFormUserRoleStatusToGQLStatus,
  PlatformRiskToGQLRisk,
  SortDirection,
  PlatformDistributionStatusToGQLStatus,
  PlatformReviewStatusToGQLStatus,
} from './TableView';
import { DateInterval } from './DateInterval';
import { ClientFormStatus } from './ClientFormStatus';
import { RiskRating } from '../utils/RiskUtils';
import DateUtils from '../utils/DateUtils';
import { ARCHIVED_VALUE } from '../components/activity-list/Activities';
import { ActionTypeNames } from '../components/form/ActionTypes';

export enum DateFilterOptions {
  TODAY = 'today',
  OVERDUE = 'overdue',
  THIS_WEEK = 'thisWeek',
  MORE_THAN = 'moreThan',
  LESS_THAN = 'lessThan',
  BETWEEN = 'between',
  NOT_SET = 'notSet',
}

export enum ProgressFilterOptions {
  EXACT = 'exact',
  MORE_THAN = 'more-than',
  LESS_THAN = 'less-than',
  BETWEEN = 'between',
}

export enum CurrencyFilterOptions {
  EXACT = 'exact',
  MORE_THAN = 'more-than',
  LESS_THAN = 'less-than',
  BETWEEN = 'between',
}

export type DateFilterValue = {
  selectedOption: DateFilterOptions;
  moreThan: { value: number; interval: DateInterval } | null;
  lessThan: { value: number; interval: DateInterval } | null;
  between: { start: string | null; end: string | null } | null;
};

export type StatusFilterValue = {
  clientFormStatusFilters: ClientFormStatus[];
  distributionStatusFilters: DistributionStatusFilter[];
  periodicReviewStatusFilters: PeriodicReviewStatus[];
};

export type ProgressFilterValue = {
  selectedOption: ProgressFilterOptions;
  exact: number | null;
  moreThan: number | null;
  lessThan: number | null;
  between: { start: number; end: number } | null;
};

export type MembersFilterValue = string[];

export type GenericFilterValue = (string | false)[];
export type RiskFilterValue = RiskRating[];
export type YesNoFilterValue = ('yes' | 'no' | 'na' | false)[];

export type CheckboxFilterValue = boolean;

export type CurrencyFilterValue = {
  selectedOption: CurrencyFilterOptions;
  exact: { currencyCode: string; amount: string } | null;
  moreThan: { currencyCode: string; amount: string } | null;
  lessThan: { currencyCode: string; amount: string } | null;
  between: { start: { currencyCode: string; amount: string }; end: { currencyCode: string; amount: string } } | null;
};

export type ColumnFilters = {
  [columnConfigValue: string]: { columnConfig: ColumnConfig; filter: FilterValue } | undefined;
};

export type Filters = {
  [templateId: string]: ColumnFilters;
};

export type FilterValue =
  | DateFilterValue
  | GenericFilterValue
  | RiskFilterValue
  | StatusFilterValue
  | ProgressFilterValue
  | CheckboxFilterValue
  | MembersFilterValue
  | CurrencyFilterValue
  | YesNoFilterValue;

export const buildFilters = (columns: ColumnConfig[], actions: Record<string, any>, searchTerm: string | undefined): Record<string, unknown> => {
  const filterWhereInput: Record<string, unknown> = {};
  const filterFullInput: Record<string, unknown> = { where: filterWhereInput };

  if (!columns) return filterWhereInput;

  const actionFilterConditions: any[] = [];

  //MetaData
  columns.forEach((column) => {
    if (column.filter === undefined || column.filter === null) return;

    const filterValue = column.filter;

    // Meta data fields
    if (column.type === ColumnType.MetaData) {
      const graphQLMetadataField = graphQLMetadataFields[column.value as keyof typeof graphQLMetadataFields];
      let graphQLField = graphQLMetadataField.field;
      const fieldName = column.value as (typeof tableViewMetadata)[keyof typeof tableViewMetadata];

      // Date Filter
      if (fieldName === 'dueDate' || fieldName === 'effectiveDate' || fieldName === 'lastModified' || fieldName === 'createdOn') {
        filterWhereInput[graphQLField] = buildDateFilterInput(filterValue as DateFilterValue);
      }

      // Status filter
      else if (fieldName === 'status') {
        const status = filterValue as StatusFilterValue;
        if (status.clientFormStatusFilters.length > 0) {
          filterWhereInput[graphQLField] = buildClientFormStatusFilterInput(
            status.clientFormStatusFilters.filter((status) => (status as number) !== ARCHIVED_VALUE),
          );

          if (status.clientFormStatusFilters.find((status) => (status as number) === ARCHIVED_VALUE)) {
            filterWhereInput['archivedUtc'] = { neq: null };
          }
        }

        if (status.distributionStatusFilters) {
          filterFullInput.distributionStatuses = buildDistributionStatusFilterInput(status.distributionStatusFilters);
        }

        if (status.periodicReviewStatusFilters) {
          filterFullInput.periodicReviewStatuses = buildPeriodicsStatusFilterInput(status.periodicReviewStatusFilters);
        }
      }

      // Risk filter
      else if (fieldName === 'risk') {
        filterWhereInput[graphQLField] = buildRiskFilterInput(filterValue as RiskRating[]);
      }

      // Progress filter
      else if (fieldName === 'progress') {
        filterWhereInput[graphQLField] = buildProgressFilterInput(filterValue as ProgressFilterValue);
      }

      // Members filter
      else if (fieldName === 'createdBy') {
        graphQLField = graphQLMetadataField.auxillaryFields![0];
        filterWhereInput[graphQLField] = { in: filterValue };
      }
      // Attachments filter
      else if (fieldName === 'allAttachments') {
        filterFullInput.attachmentIds = (filterValue as GenericFilterValue).filter((x) => x !== false);
      }

      // References filter
      else if (fieldName === 'allReferences') {
        filterFullInput.referenceIds = (filterValue as GenericFilterValue).filter((x) => x !== false);
      }
    }
  });

  //Action Fields
  columns.forEach((column) => {
    if (column.filter === undefined || column.filter === null) return;

    const filterValue = column.filter;

    if (column.type === ColumnType.Action) {
      let resultFilter: string[] | string[][] = [];
      const actionType = actions[column.value].type as ActionTypeNames;

      if (actionType === 'TickboxAction') {
        resultFilter = [filterValue ? '$.checked = true' : '$. = null'];
      } else if (actionType === 'Address') {
        resultFilter = (filterValue as GenericFilterValue).map((x) => (x ? `$.address = '${x}'` : '$. = null'));
      } else if (actionType === 'EmailInputAction') {
        resultFilter = (filterValue as GenericFilterValue).map((x) => (x ? `$. = '${x}'` : '$. = null'));
      } else if (actionType === 'FileOrLinkUploadAction') {
        resultFilter = (filterValue as GenericFilterValue).map((x) => (x ? `$.fileInfo.name = '${x}'` : '$. = null'));
      } else if (actionType === 'LinkAction') {
        resultFilter = (filterValue as GenericFilterValue).map((x) => (x ? `$. = '${x}'` : '$. = null'));
      } else if (actionType === 'MultiFileUploadAction') {
        resultFilter = (filterValue as GenericFilterValue).map((x) => (x ? `$. ~= '${x}'` : '$. = null'));
      } else if (actionType === 'NumberInputAction') {
        resultFilter = (filterValue as GenericFilterValue).map((x) => (x ? `$. = ${x}` : '$. = null'));
      } else if (actionType === 'PhoneNumberAction') {
        resultFilter = (filterValue as GenericFilterValue).map((x) => (x ? `$.phoneNumber = ${x}` : '$. = null'));
      } else if (actionType === 'PickListAction') {
        resultFilter = (filterValue as GenericFilterValue).map((x) => (x ? `$. ~= '${x}'` : '$. = null'));
      } else if (actionType === 'SingleFileUploadAction') {
        resultFilter = (filterValue as GenericFilterValue).map((x) => (x ? `$.name = '${x}'` : '$. = null'));
      } else if (actionType === 'SelectListAction') {
        resultFilter = (filterValue as GenericFilterValue).map((x) => (x ? `$. ~= '${x}'` : '$. = null'));
      } else if (actionType === 'TextInputAction') {
        resultFilter = (filterValue as GenericFilterValue).map((x) => (x ? `$. = '${x}'` : '$. = null'));
      } else if (actionType === 'YesNoAction') {
        resultFilter = (filterValue as YesNoFilterValue).map((x) => (x ? `$.id = '${x}'` : '$. = null'));
      } else if (actionType === 'DateAction') {
        resultFilter = [buildDateActionInputFilter(filterValue as DateFilterValue)];
      } else if (actionType === 'CurrencyInputAction') {
        resultFilter = [buildCurrencyActionFilterInput(filterValue as CurrencyFilterValue)];
      } else if (actionType === 'ResourcePicklistAction') {
        resultFilter = (filterValue as GenericFilterValue).map((x) => (x ? `$. ~= '${x}'` : '$. = null'));
      } else if (actionType === 'DocumentReferenceAction') {
        resultFilter = (filterValue as GenericFilterValue).map((x) => (x ? `$. ~= '${x}'` : '$. = null'));
      }

      const condition = [];
      const baseQuery = { isLatest: { eq: true }, actionId: { eq: column.value } };
      resultFilter.forEach((x) => {
        if (Array.isArray(x)) {
          condition.push({ answers: { some: { ...baseQuery, and: x.map((y) => ({ data: { jsonpath: y } })) } } });
        } else {
          condition.push({
            answers: { some: { ...baseQuery, data: { jsonpath: x } } },
          });
        }
      });

      if (filterValue === noDataId || (Array.isArray(filterValue) && (filterValue as any).includes(noDataId))) {
        condition.push({
          answers: { none: baseQuery },
        });
      }

      if (condition.length > 0) {
        actionFilterConditions.push({ or: condition });
      }
    }
  });

  const userFilter = buildUserFilter(columns);

  const andCondition = searchTerm
    ? [...actionFilterConditions, ...userFilter, { subtitle: { contains: searchTerm } }]
    : [...actionFilterConditions, ...userFilter];

  if (andCondition.length > 0) {
    filterWhereInput.and = andCondition;
  }

  return filterFullInput;
};

const buildUserFilter = (columns: ColumnConfig[]) => {
  //users
  const userColumns = columns
    .filter((column) => {
      return column.filter !== undefined && column.filter !== null && column.filter !== undefined && column.type === ColumnType.MetaData;
    })
    .filter((column) => ['owner', 'editor', 'viewer', 'approver', 'reviewer'].includes(column.value));

  const userFilters = userColumns.reduce((acc: any[], column) => {
    if (column.filter === undefined || column.filter === null) return acc;
    const fieldName = column.value as (typeof tableViewMetadata)[keyof typeof tableViewMetadata];
    // @ts-expect-error - getting the role
    const role = MetaClientFormUserRoleStatusToGQLStatus[fieldName];
    const userWithRoleIn = { users: { some: { role: { eq: role }, userId: { in: column.filter } } } };
    acc.push(userWithRoleIn);
    return acc;
  }, []);

  const memberColumn = columns.find((column) => column.value === 'members');
  if (memberColumn && memberColumn.filter) {
    const userIdIn = { users: { some: { userId: { in: memberColumn.filter } } } };
    userFilters.push(userIdIn);
  }

  return userFilters || [];
};

const buildDateFilterInput = (filter: DateFilterValue) => {
  const dateFilterInput: Record<string, unknown> = {};

  switch (filter.selectedOption) {
    case DateFilterOptions.TODAY:
      dateFilterInput.eq = DateUtils.todayStart;
      break;
    case DateFilterOptions.OVERDUE:
      dateFilterInput.lt = DateUtils.todayStart;
      break;
    case DateFilterOptions.MORE_THAN:
      if (filter.moreThan) {
        dateFilterInput.gt = DateUtils.setDate(filter.moreThan.value, filter.moreThan.interval);
      }
      break;
    case DateFilterOptions.LESS_THAN:
      if (filter.lessThan) {
        dateFilterInput.lt = DateUtils.setDate(filter.lessThan.value, filter.lessThan.interval);
      }
      break;
    case DateFilterOptions.BETWEEN:
      if (filter.between) {
        if (filter.between.start) {
          dateFilterInput.gte = filter.between.start;
        }
        if (filter.between.end) {
          dateFilterInput.lte = filter.between.end;
        }
      }
      break;
    case DateFilterOptions.NOT_SET:
      dateFilterInput.eq = null;
      break;

    default:
      break;
  }

  return dateFilterInput;
};

const buildDateActionInputFilter = (filter: DateFilterValue) => {
  switch (filter.selectedOption) {
    case DateFilterOptions.TODAY:
      return [`$. = ${DateUtils.todayStart.toISOString()} `];
    case DateFilterOptions.OVERDUE:
      return [`$. < ${DateUtils.todayStart.toISOString()} `];
    case DateFilterOptions.MORE_THAN:
      if (filter.moreThan) {
        return [`$. > ${DateUtils.setDate(filter.moreThan.value, filter.moreThan.interval).toISOString()} `];
      }
      break;
    case DateFilterOptions.LESS_THAN:
      if (filter.lessThan) {
        return [`$. < ${DateUtils.setDate(filter.lessThan.value, filter.lessThan.interval).toISOString()} `];
      }
      break;
    case DateFilterOptions.BETWEEN:
      if (filter.between) {
        const result = [];
        if (filter.between.start) {
          result.push(`$. >= '${filter.between.start}'`);
        }
        if (filter.between.end) {
          result.push(`$. <= '${filter.between.end}'`);
        }
        return result;
      }
      break;
    case DateFilterOptions.NOT_SET:
      return [`$. = null`];

    default:
      break;
  }

  return [];
};

const buildClientFormStatusFilterInput = (filter: ClientFormStatus[]) => {
  const statusFilterInput: Record<string, unknown> = {};
  const enumStatusValues = filter.map((status) => PlatformStatusToGQLStatus[status]);

  if (enumStatusValues.length > 1) {
    statusFilterInput.in = enumStatusValues;
  } else if (enumStatusValues.length === 1) {
    statusFilterInput.eq = enumStatusValues[0];
  }

  return statusFilterInput;
};

const buildDistributionStatusFilterInput = (filter: DistributionStatusFilter[]) => {
  if (filter.length === 0) return null;
  return filter.map((status) => PlatformDistributionStatusToGQLStatus[status]);
};

const buildPeriodicsStatusFilterInput = (filter: PeriodicReviewStatus[]) => {
  if (filter.length === 0) return null;
  return filter.map((status) => PlatformReviewStatusToGQLStatus[status]);
};

const buildRiskFilterInput = (filter: RiskRating[]) => {
  const riskFilterInput: Record<string, unknown> = {};
  const enumStatusValues = filter.map((riskRating) => PlatformRiskToGQLRisk[riskRating as keyof typeof PlatformRiskToGQLRisk]);

  if (enumStatusValues.length > 1) {
    riskFilterInput.in = enumStatusValues;
  } else if (enumStatusValues.length === 1) {
    riskFilterInput.eq = enumStatusValues[0];
  }

  return riskFilterInput;
};

const buildProgressFilterInput = (filter: ProgressFilterValue): Record<string, unknown> => {
  const progressFilterInput: Record<string, unknown> = {};

  switch (filter.selectedOption) {
    case ProgressFilterOptions.EXACT:
      if (filter.exact !== null) {
        progressFilterInput.eq = filter.exact / 100;
      }
      break;
    case ProgressFilterOptions.MORE_THAN:
      if (filter.moreThan !== null) {
        progressFilterInput.gt = filter.moreThan / 100;
      }
      break;
    case ProgressFilterOptions.LESS_THAN:
      if (filter.lessThan !== null) {
        progressFilterInput.lt = filter.lessThan / 100;
      }
      break;
    case ProgressFilterOptions.BETWEEN:
      if (filter.between) {
        if (filter.between.start !== null) {
          progressFilterInput.gte = filter.between.start / 100;
        }
        if (filter.between.end !== null) {
          progressFilterInput.lte = filter.between.end / 100;
        }
      }
      break;

    default:
      break;
  }

  return progressFilterInput;
};

const buildCurrencyActionFilterInput = (filter: CurrencyFilterValue) => {
  switch (filter.selectedOption) {
    case CurrencyFilterOptions.EXACT:
      if (filter.exact) return ['$.currencyCode = ' + filter.exact.currencyCode, '$.amount = ' + filter.exact.amount];
      break;
    case CurrencyFilterOptions.MORE_THAN:
      if (filter.moreThan) return ['$.currencyCode = ' + filter.moreThan.currencyCode, '$.amount > ' + filter.moreThan.amount];
      break;
    case CurrencyFilterOptions.LESS_THAN:
      if (filter.lessThan) return ['$.currencyCode = ' + filter.lessThan.currencyCode, '$.amount < ' + filter.lessThan.amount];
      break;
    case CurrencyFilterOptions.BETWEEN:
      if (filter.between) {
        return [
          '$.currencyCode = ' + filter.between.start.currencyCode,
          '$.amount >= ' + filter.between.start.amount,
          '$.amount <= ' + filter.between.end.amount,
        ];
      }
      break;
    default:
      break;
  }

  return [];
};

export const buildOrdering = (columns: ColumnConfig[]): Record<string, 'ASC' | 'DESC'> => {
  const orderingConditions: Record<string, 'ASC' | 'DESC'> = {};

  columns.forEach((column) => {
    if (column.sortDirection !== undefined) {
      const { value, type, sortDirection } = column;
      const direction = sortDirection === SortDirection.Ascending ? 'ASC' : 'DESC';
      if (type === ColumnType.MetaData) {
        const graphQLMetadataField = graphQLMetadataFields[value as keyof typeof graphQLMetadataFields];
        const graphQLField = graphQLMetadataField?.field;
        if (graphQLField) {
          orderingConditions[graphQLField] = direction;
        }
      }
    }
  });

  // Fall back to sorting by subTitle if no ordering conditions are set
  if (Object.keys(orderingConditions).length === 0) {
    const subTitleField = graphQLMetadataFields[tableViewMetadata.subTitle as keyof typeof graphQLMetadataFields].field;
    orderingConditions[subTitleField] = 'ASC';
  }

  return orderingConditions;
};
