import { ReactNode, lazy } from 'react';
import { tableViewMetadata } from '../../../models/TableView';

type MetaDataType = (typeof tableViewMetadata)[keyof typeof tableViewMetadata];
type Renderer = {
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  render: (value: any) => ReactNode;
};

export type DataRendersKeys = MetaDataType;

export const DataRenderers: Record<DataRendersKeys, Renderer> = {
  subTitle: {
    render: lazy(() => import('./DefaultTextRenderer')),
  },
  dueDate: {
    render: lazy(() => import('./DateRenderer')),
  },
  members: {
    render: lazy(() => import('./MembersRenderer')),
  },
  progress: {
    render: lazy(() => import('./ProgressRenderer')),
  },
  createdBy: {
    render: lazy(() => import('./UserRenderer')),
  },
  lastModified: {
    render: lazy(() => import('./DateTimeRenderer')),
  },
  createdOn: {
    render: lazy(() => import('./DateTimeRenderer')),
  },
  effectiveDate: {
    render: lazy(() => import('./DateRenderer')),
  },
  owner: {
    render: lazy(() => import('./UserRenderer')),
  },
  editor: {
    render: lazy(() => import('./UserRenderer')),
  },
  viewer: {
    render: lazy(() => import('./UserRenderer')),
  },
  approver: {
    render: lazy(() => import('./UserRenderer')),
  },
  reviewer: {
    render: lazy(() => import('./UserRenderer')),
  },
  risk: {
    render: lazy(() => import('./RiskRenderer')),
  },
  status: {
    render: lazy(() => import('./FormStatusRenderer')),
  },
  allReferences: {
    render: lazy(() => import('./ReferencesRenderer')),
  },
  allAttachments: {
    render: lazy(() => import('./AttachmentsRenderer')),
  },
  version: {
    render: lazy(() => import('./DefaultTextRenderer')),
  },
  periodicNextReview: {
    render: lazy(() => import('./DateRenderer')),
  },
};
