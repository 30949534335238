import EmptyTableDataSvg from '../../assets/images/empty-table-data.svg';
import Button, { ButtonSize, ButtonType } from '../shared/form-control/Button';
import { Heading, HeadingSize } from '../shared/text/Heading';
import { useTranslation } from 'react-i18next';
const EmptyTableData = () => {
  const { t } = useTranslation(['table-view']);
  const isFiltersApplied = false;
  return (
    <div className="flex flex-col items-center gap-6 text-center">
      <img src={EmptyTableDataSvg} alt={t('empty-table-data.title')} className="max-w-[145px]" />
      <div>
        <Heading size={HeadingSize.H3} textColor="text-black" className="my-2">
          {t('empty-table-data.title')}
        </Heading>
        <div className="text-gray-2"> {t('empty-table-data.description')}</div>
      </div>
      {isFiltersApplied && (
        <div className="flex items-center justify-center gap-2">
          <Button size={ButtonSize.S} type={ButtonType.SECONDARY}>
            {t('empty-table-data.clear-all-filters')}
          </Button>
          <Button size={ButtonSize.S} type={ButtonType.PRIMARY}>
            {t('empty-table-data.clear-search')}
          </Button>
        </div>
      )}
    </div>
  );
};

export default EmptyTableData;
