/* eslint-disable @typescript-eslint/no-explicit-any */
import { FC, useCallback, useEffect, useMemo, useState } from 'react';
import { ModalContext } from '../../contexts/ModalContext';
import StandardModal from '../shared/modal/variants/StandardModal';
import { useTableView } from '../../contexts/table-view/TableViewContext';
import { freezeLineId, newViewId } from '../../models/TableView';
import InlineEditor from '../shared/form-control/InlineEditor';
import ViewIcon from '../shared/icon/ViewIcon';
import { TabStrip } from '../shared/tab-strip/TabStrip';
import Checkbox, { SliderSize } from '../shared/form-control/Checkbox';
import { FormConfig } from '../../models/Form';
import ClientTemplateModuleService from '../../services/ClientTemplateModuleService';
import { useRecoilValue } from 'recoil';
import { currentClientAtom } from '../../recoil/atoms/Clients';
import LanguageUtils from '../../utils/LanguageUtils';
import TemplateFormService from '../../services/TemplateFormService';
import { mouseAndKeyboardCallbackProps } from '../../utils/ComponentUtils';
import SkeletonLoader from '../shared/skeleton-loader/SkeletonLoader';
import SelectableActionsRenderer from './SelectableActionsRenderer';
import ObjectUtils from '../../utils/ObjectUtils';
import { closestCenter, DndContext, DragEndEvent, DragOverEvent, DragOverlay, DragStartEvent } from '@dnd-kit/core';
import { ColumnConfig, ColumnType, TableView, TableViewColumnConfiguration, tableViewMetadata, TableViewMetaDataKey } from '../../models/TableView';
import SortableColumnConfig from './SortableColumnConfig';
import { arrayMove, SortableContext, verticalListSortingStrategy } from '@dnd-kit/sortable';
import SortableFreezeLine from './SortableFreezeLine';
import { useTranslation } from 'react-i18next';
import SelectedTemplateHeader from './SelectedTemplateHeader';
import SelectableMetaDataRenderer from './SelectableMetaDataRenderer';
import { Heading, HeadingSize } from '../shared/text/Heading';
import FormUtils from '../../utils/FormUtils';
import { TableViewEditContext } from '../../contexts/table-view/TableViewEditContext';
import SortableTemplate from './SortableTemplate';

export enum TableViewConfigDroppableTypes {
  Columns = 'columns',
  Templates = 'templates',
  Actions = 'actions',
  Meta = 'meta',
}

export type TableViewConfigDraggableItem = {
  type: TableViewConfigDroppableTypes;
  item: ColumnConfig;
};

type Props = {
  open: boolean;
  onClose: () => void;
  onApplyChanges: () => void;
};

const TableViewConfigModal: FC<Props> = (props) => {
  const { open, onClose, onApplyChanges } = props;
  const { clientModuleId, templateModuleSectionId, selectedTableView, setSelectedTableView, selectedOriginalTableView, isDirty } = useTableView();
  const [templates, setTemplates] = useState<FormConfig[]>([]);
  const [selectedTemplateId, setSelectedTemplateId] = useState('');
  const [selectedTemplate, setSelectedTemplate] = useState<FormConfig>();
  const currentClient = useRecoilValue(currentClientAtom);
  const [templatesLoading, setTemplatesLoading] = useState(false);
  const [templateLoading, setTemplateLoading] = useState(false);
  const [activeDraggable, setActiveDraggable] = useState<{ actionId: string; label: string } | null>(null);
  const { t } = useTranslation(['table-view']);
  const [overIndex, setOverIndex] = useState<number | null>(null);
  const [selectedTableViewInternal, setSelectedTableViewInternal] = useState<TableView | null>(null);

  useEffect(() => {
    if (open) {
      setSelectedTableViewInternal(ObjectUtils.DeepClone(selectedTableView));
    }
  }, [open, selectedTableView]);

  const onAppplyChangesInternal = useCallback(() => {
    setSelectedTableView && selectedTableViewInternal && setSelectedTableView(ObjectUtils.DeepClone(selectedTableViewInternal));
    onApplyChanges();
  }, [onApplyChanges, selectedTableViewInternal, setSelectedTableView]);

  const onCloseInternal = useCallback(() => {
    if (selectedTableViewInternal?.id !== newViewId) {
      setSelectedTableView && selectedOriginalTableView && setSelectedTableView(ObjectUtils.DeepClone(selectedOriginalTableView));
    }
    onClose();
  }, [onClose, selectedOriginalTableView, selectedTableViewInternal?.id, setSelectedTableView]);

  const updateTableView = useCallback(
    (updatedColumns: ColumnConfig[]) => {
      if (selectedTemplate) {
        setSelectedTableViewInternal((prev) => {
          if (!prev) return prev;
          return {
            ...prev,
            columnConfigurations: {
              ...prev.columnConfigurations,
              [selectedTemplate.id]: {
                ...prev.columnConfigurations?.[selectedTemplate.id],
                templateId: selectedTemplate.id,
                columns: updatedColumns,
                enabled: true, // always enable the template when we change something
                enableIndexNumbering: prev.columnConfigurations?.[selectedTemplate.id]?.enableIndexNumbering ?? false,
                sortIndex: prev.columnConfigurations?.[selectedTemplate.id]?.sortIndex ?? 0,
              },
            },
          };
        });
      }
    },
    [selectedTemplate],
  );

  const onEnableTemplateChange = useCallback(
    (isEnabled: boolean, templateId: string) => {
      const sortIndex = templates.findIndex((template) => template.id === templateId);
      setSelectedTableViewInternal((prev) => {
        if (!prev) return prev;
        return {
          ...prev,
          columnConfigurations: {
            ...prev.columnConfigurations,
            [templateId]: {
              ...prev.columnConfigurations?.[templateId],
              columns: prev.columnConfigurations?.[templateId]?.columns || [],
              enabled: isEnabled,
              sortIndex,
              enableIndexNumbering: prev.columnConfigurations?.[templateId]?.enableIndexNumbering ?? false,
            },
          },
        };
      });
    },
    [templates],
  );

  const onViewNameChange = useCallback((name: string) => {
    setSelectedTableViewInternal((prev) => {
      if (!prev) return prev;
      return {
        ...prev,
        name: name,
      };
    });
  }, []);

  const updateFreezeStatus = (columns: ColumnConfig[]) => {
    const targetIndex = columns.findIndex((column) => column.value === freezeLineId);
    return columns.map((column, index) => {
      if (index < targetIndex) {
        return { ...column, freeze: true };
      } else {
        return { ...column, freeze: false };
      }
    });
  };

  const selectedColumnConfiguration: TableViewColumnConfiguration = useMemo(
    () =>
      selectedTemplate
        ? selectedTableViewInternal?.columnConfigurations?.[selectedTemplate.id] || { columns: [], enabled: true, enableIndexNumbering: false }
        : { columns: [], enabled: true, enableIndexNumbering: false },
    [selectedTableViewInternal?.columnConfigurations, selectedTemplate],
  );

  const selectTemplate = useCallback((template: FormConfig) => {
    setTemplateLoading(true);
    setSelectedTemplateId(template.id);
    TemplateFormService.getFormTemplate(template.id)
      .then((res) => {
        setSelectedTemplate(res.data);
      })
      .finally(() => {
        setTemplateLoading(false);
      });
  }, []);

  useEffect(() => {
    if (!selectedColumnConfiguration.columns.some((col) => col.value === freezeLineId)) {
      updateTableView([...selectedColumnConfiguration.columns, { value: freezeLineId, type: ColumnType.Action }]);
    }
    // Ensure Title is always configured
    if (!selectedColumnConfiguration.columns.some((col) => col.value === tableViewMetadata.subTitle)) {
      updateTableView([...selectedColumnConfiguration.columns, { value: tableViewMetadata.subTitle, type: ColumnType.MetaData, freeze: true }]);
    }
  }, [selectedColumnConfiguration.columns, updateTableView, selectedTemplate]);

  useEffect(() => {
    if (currentClient && open) {
      setTemplatesLoading(true);
      new ClientTemplateModuleService(currentClient.id)
        .getAllTemplateForms({ clientModuleId })
        .then((res) => {
          const sectionTemplates = res.data.filter((template) => template.templateModuleSectionId === templateModuleSectionId);
          setTemplates(
            sectionTemplates.sort((a, b) => {
              // Don't use selectedTableViewInternal here, as we only want to apply this sorting when the modal is opened
              const aEnabled = selectedTableView?.columnConfigurations?.[a.id]?.enabled ?? false;
              const bEnabled = selectedTableView?.columnConfigurations?.[b.id]?.enabled ?? false;

              if (Number(bEnabled) !== Number(aEnabled)) {
                return Number(bEnabled) - Number(aEnabled);
              }

              const aSortIndex = selectedTableView?.columnConfigurations?.[a.id]?.sortIndex ?? -1;
              const bSortIndex = selectedTableView?.columnConfigurations?.[b.id]?.sortIndex ?? -1;

              if (aSortIndex !== bSortIndex) {
                return aSortIndex - bSortIndex;
              }

              return 0;
            }),
          );
          const defaultSelectedTemplate = sectionTemplates[0];
          if (defaultSelectedTemplate) {
            selectTemplate(defaultSelectedTemplate);
          }
        })
        .finally(() => {
          setTemplatesLoading(false);
        });
    }
  }, [currentClient, templateModuleSectionId, open, clientModuleId, selectTemplate, selectedTableView?.columnConfigurations]);

  const formActions = useMemo(
    () => selectedTemplate?.sections.flatMap((section) => section.actions.filter((x) => !x.noninteractive && x.type !== 'AdHocFieldsAction')),
    [selectedTemplate?.sections],
  );

  const handleDragStart = (event: DragStartEvent) => {
    const { active } = event;
    const data = active.data.current as TableViewConfigDraggableItem;
    if (data.type === TableViewConfigDroppableTypes.Meta) {
      setActiveDraggable({ actionId: data.item.value, label: t(`meta.${data.item.value as TableViewMetaDataKey}`) });
    } else if (data.type === TableViewConfigDroppableTypes.Actions) {
      const actionId = data.item.value;
      const draggableAction = formActions?.find((action) => action.id === actionId);
      const translatedData = draggableAction ? LanguageUtils.getActionDataTranslation(ObjectUtils.DeepClone(draggableAction)) : {};
      if (draggableAction) {
        setActiveDraggable({ actionId: draggableAction.id, label: translatedData?.question || translatedData?.title });
      }
    } else if (data.type === TableViewConfigDroppableTypes.Templates) {
      const template = active.data.current?.item as FormConfig;
      setActiveDraggable({ actionId: active.id as string, label: LanguageUtils.getTranslation('title', template.translations || {}) });
    }
  };

  const handleDragOver = (event: DragOverEvent) => {
    const { over, active } = event;
    if (!over || !active) {
      setOverIndex(null);
      return;
    }
    const activeData = active.data.current as TableViewConfigDraggableItem;
    if (activeData.type === TableViewConfigDroppableTypes.Actions || activeData.type === TableViewConfigDroppableTypes.Meta) {
      const overData = over.data.current as TableViewConfigDraggableItem;
      const overIndex = selectedColumnConfiguration.columns.findIndex((column) => {
        return column.value === overData.item.value;
      });
      setOverIndex(overIndex !== -1 ? overIndex : selectedColumnConfiguration.columns.length - 1);
    }
  };

  const handleDragEnd = (event: DragEndEvent) => {
    const { active, over } = event;
    if (!over || !active) return;

    const activeData = active.data.current as TableViewConfigDraggableItem;
    const overData = over.data.current as TableViewConfigDraggableItem;
    setActiveDraggable(null);

    if (overData.type === TableViewConfigDroppableTypes.Columns) {
      const columnIds = selectedColumnConfiguration.columns.map((x) => x.value);
      const shouldAddToColumns = !columnIds.includes(activeData.item.value);
      if (shouldAddToColumns) {
        addColumn(activeData.item);
      } else if (active.id !== over.id) {
        moveColumn(activeData, overData);
      }
    }
    if (overData.type === TableViewConfigDroppableTypes.Templates && activeData.type === TableViewConfigDroppableTypes.Templates) {
      const activeTemplate = active.data.current?.item as FormConfig;
      const overTemplate = over.data.current?.item as FormConfig;
      moveTemplate(activeTemplate, overTemplate);
    }
  };

  const addColumn = (columnConfig: ColumnConfig) => {
    const updatedColumns = [
      ...selectedColumnConfiguration.columns.slice(0, overIndex && overIndex !== -1 ? overIndex : selectedColumnConfiguration.columns.length),
      columnConfig,
      ...selectedColumnConfiguration.columns.slice(overIndex && overIndex !== -1 ? overIndex : selectedColumnConfiguration.columns.length),
    ];
    setOverIndex(null);
    updateTableView(updateFreezeStatus(updatedColumns));
  };

  const moveColumn = (activeData: TableViewConfigDraggableItem, overData: TableViewConfigDraggableItem) => {
    // Prevent moving the subtitle column or moving any column before/after subtitle
    if (activeData.item.value === tableViewMetadata.subTitle || overData.item.value === tableViewMetadata.subTitle) return;

    const oldIndex = selectedColumnConfiguration.columns.findIndex((col) => col.value === activeData.item.value);
    const newIndex = selectedColumnConfiguration.columns.findIndex((col) => col.value === overData.item.value);

    if (oldIndex !== newIndex) {
      // Move columns, but ensure subtitle remains first
      let updatedColumns = arrayMove(selectedColumnConfiguration.columns, oldIndex, newIndex);

      // Ensure subtitle remains at the first index
      const subtitleColumn = updatedColumns.find((col) => col.value === tableViewMetadata.subTitle);
      if (subtitleColumn) {
        updatedColumns = [subtitleColumn, ...updatedColumns.filter((col) => col.value !== tableViewMetadata.subTitle)];
      }

      updateTableView(updateFreezeStatus(updatedColumns));
    }
  };

  const moveTemplate = (activeTemplate: FormConfig, overTemplate: FormConfig) => {
    const oldIndex = templates.findIndex((template) => {
      return template.id === activeTemplate.id;
    });

    const newIndex = templates.findIndex((template) => {
      return template.id === overTemplate.id;
    });

    if (oldIndex !== newIndex) {
      const updatedTemplates = arrayMove(templates, oldIndex, newIndex);
      const updatedColumnConfigurations = { ...selectedTableViewInternal?.columnConfigurations };

      updatedTemplates.forEach((template, index) => {
        if (updatedColumnConfigurations[template.id]) {
          updatedColumnConfigurations[template.id].sortIndex = index;
        }
      });

      // Update templates and column configurations
      setTemplates(updatedTemplates);
      setSelectedTableViewInternal((prev) => {
        if (!prev) return prev;
        return {
          ...prev,
          columnConfigurations: updatedColumnConfigurations,
        };
      });
    }
  };

  const selectAllActions = useCallback(() => {
    const actionIds = formActions?.map((action) => action.id) || [];
    const existingColumns = selectedColumnConfiguration.columns.filter((col) => actionIds.includes(col.value) && col.type === ColumnType.Action);
    const newColumns = actionIds
      .filter((actionId) => !existingColumns.some((col) => col.value === actionId && col.type === ColumnType.Action))
      .map((actionId) => ({ value: actionId, type: ColumnType.Action }));
    const updatedColumns = [...selectedColumnConfiguration.columns, ...newColumns];
    updateTableView(updatedColumns);
  }, [selectedColumnConfiguration.columns, formActions, updateTableView]);

  const clearAllActions = useCallback(() => {
    const updatedColumns = selectedColumnConfiguration.columns.filter((col) => col.type !== ColumnType.Action);
    updateTableView(updatedColumns);
  }, [selectedColumnConfiguration.columns, updateTableView]);

  const selectAllMetaData = useCallback(() => {
    const metaData = Object.values(tableViewMetadata);
    const existingColumns = selectedColumnConfiguration.columns.filter(
      (col) => metaData.includes(col.value as TableViewMetaDataKey) && col.type === ColumnType.MetaData,
    );
    const newColumns = metaData
      .filter((key) => !existingColumns.some((col) => col.value === key && col.type === ColumnType.MetaData))
      .map((metaDataFieldName) => ({ value: metaDataFieldName, type: ColumnType.MetaData }));
    const updatedColumns = [...selectedColumnConfiguration.columns, ...newColumns];
    updateTableView(updatedColumns);
  }, [selectedColumnConfiguration.columns, updateTableView]);

  const clearAllMetaData = useCallback(() => {
    const updatedColumns = selectedColumnConfiguration.columns.filter((col) => col.type !== ColumnType.MetaData);
    updateTableView(updatedColumns);
  }, [selectedColumnConfiguration.columns, updateTableView]);

  const onRemoveColumn = useCallback(
    (column: ColumnConfig) => {
      const updatedColumns = selectedColumnConfiguration.columns.filter((col) => col.value !== column.value);
      updateTableView(updatedColumns);
    },
    [selectedColumnConfiguration.columns, updateTableView],
  );

  return (
    <TableViewEditContext.Provider value={{ selectedTableView: selectedTableViewInternal, setSelectedTableView: setSelectedTableViewInternal }}>
      <ModalContext.Provider value={{ open, onClose: onCloseInternal, modalWidth: 'w-full sm:w-11/12 md:w-10/12 lg:w-10/12 xl:w-10/12 2xl:w-9/12' }}>
        <StandardModal
          title={
            <InlineEditor
              prefix={<ViewIcon className="mb-1 mr-2 h-6 w-6 " />}
              className="text-dpm-20"
              inputClassName="!p-0"
              value={selectedTableViewInternal?.name || ''}
              onChange={onViewNameChange}
            />
          }
          confirmDisabled={false}
          confirmButtonTitle={t('buttons.apply')}
          cancelButtonTitle={isDirty ? t('buttons.discard') : t('buttons.cancel')}
          onConfirmClick={onAppplyChangesInternal}
          onCancelClick={onCloseInternal}
          bare
        >
          {' '}
          <DndContext collisionDetection={closestCenter} onDragStart={handleDragStart} onDragEnd={handleDragEnd} onDragOver={handleDragOver}>
            <DragOverlay>
              {activeDraggable ? (
                <div className="text-dpm-14 flex min-h-[16px] w-80 cursor-grabbing items-center justify-center rounded-xl border-2 bg-white bg-opacity-90 p-2 shadow-lg">
                  {activeDraggable.label}
                </div>
              ) : null}
            </DragOverlay>
            <div className="bg-background-1 flex h-full w-full gap-2 px-4 ">
              <div className="w-1/5 pt-4">
                <Heading size={HeadingSize.H6} className="mb-2 font-medium">
                  {t('templates.title')}
                </Heading>
                <div className="h-[65vh] w-full overflow-y-auto ">
                  <SkeletonLoader ready={!templatesLoading} type="listBlockRow" rows={9} size="small">
                    <ul>
                      <SortableContext items={[...templates.map((template) => template.id)]} strategy={verticalListSortingStrategy}>
                        {templates.map((template) => {
                          return (
                            <li
                              key={template.id}
                              className={`${selectedTemplateId === template.id && 'bg-accent-light-mid'} hover:bg-accent-light-mid group cursor-pointer p-2`}
                              {...mouseAndKeyboardCallbackProps(() => selectTemplate(template))}
                            >
                              <div className="flex w-full items-center justify-between">
                                <SortableTemplate template={template} />
                                <div className="ml-auto">
                                  <Checkbox
                                    slider
                                    sliderSize={SliderSize.S}
                                    value={selectedTableViewInternal?.columnConfigurations?.[template.id]?.enabled ?? false}
                                    onChange={(value) => onEnableTemplateChange(value, template.id)}
                                  />
                                </div>
                              </div>
                            </li>
                          );
                        })}
                      </SortableContext>
                    </ul>
                  </SkeletonLoader>
                </div>
              </div>

              <div className="w-1/2 px-2 pt-4">
                <TabStrip borderless contentClassName="px-4 bg-white h-full" tabBgColor="bg-white">
                  <TabStrip.TabHeader id="template-data" text={t('tabs.template-data')} value={null} data-cy="template-tab" />
                  <TabStrip.TabHeader id="meta-data" text={t('tabs.meta-data')} value={null} data-cy="meta-data-tab" />
                  <TabStrip.TabContent forId="template-data" data-cy="template-data-tab-content">
                    <SkeletonLoader ready={!templateLoading && !templatesLoading} type="listBlockRow" rows={9} size="small">
                      {selectedTemplate && (
                        <SelectedTemplateHeader selectedTemplate={selectedTemplate} onSelectAll={selectAllActions} onClearAll={clearAllActions} />
                      )}
                      {selectedTemplate && <SelectableActionsRenderer form={selectedTemplate} />}
                    </SkeletonLoader>
                  </TabStrip.TabContent>
                  <TabStrip.TabContent forId="meta-data" data-cy="meta-data-tab-content">
                    <SkeletonLoader ready={!templateLoading && !templatesLoading} type="listBlockRow" rows={9} size="small">
                      {selectedTemplate && (
                        <SelectedTemplateHeader selectedTemplate={selectedTemplate} onSelectAll={selectAllMetaData} onClearAll={clearAllMetaData} />
                      )}
                      {selectedTemplate && <SelectableMetaDataRenderer form={selectedTemplate} />}
                    </SkeletonLoader>
                  </TabStrip.TabContent>
                </TabStrip>
              </div>

              <div className="bg-background-1 w-[30%] rounded-md px-2 pt-4">
                <Heading size={HeadingSize.H6} className="mb-2 font-medium">
                  {t('selected-columns.title')}
                </Heading>
                <div className={`h-[65vh] overflow-y-auto`}>
                  <div className="mt-4">
                    <div className="text-dpm-14 rounded-md bg-white p-2 font-medium">{t('meta.subTitle')}</div>
                  </div>

                  <div>
                    {selectedColumnConfiguration.columns.filter((x) => x.value !== tableViewMetadata.subTitle).length > 0 && (
                      <SortableContext
                        items={[
                          ...selectedColumnConfiguration.columns.filter((x) => x.value !== tableViewMetadata.subTitle).map((column) => column.value),
                        ]}
                        strategy={verticalListSortingStrategy}
                      >
                        {selectedColumnConfiguration.columns
                          .filter((x) => x.value !== tableViewMetadata.subTitle)
                          .map((column, index) => {
                            let title = '';
                            if (column.type === ColumnType.Action) {
                              const action = formActions?.find((action) => action.id === column.value);
                              const translatedData = action ? LanguageUtils.getActionDataTranslation(ObjectUtils.DeepClone(action)) : {};
                              title = translatedData?.previewQuestion || translatedData?.question || translatedData?.title;
                            } else {
                              title = t(`meta.${column.value as TableViewMetaDataKey}`);
                            }

                            return (
                              <div key={index}>
                                {column.value === freezeLineId && <SortableFreezeLine />}
                                {overIndex === index && activeDraggable?.actionId && <div className="bg-gray-6 my-1 mb-2 h-9 rounded-lg p-4" />}
                                {column.value !== freezeLineId && (
                                  <SortableColumnConfig
                                    config={column}
                                    title={title}
                                    placeholders={FormUtils.formPlaceholdersToActionPlaceholderData(selectedTemplate?.placeholders)}
                                    onRemove={() => onRemoveColumn(column)}
                                  />
                                )}
                              </div>
                            );
                          })}
                        {overIndex === selectedColumnConfiguration.columns.filter((x) => x.value !== tableViewMetadata.subTitle).length &&
                          activeDraggable?.actionId && <div className="bg-gray-6 my-1 mb-2 h-9 rounded-lg p-4" />}
                      </SortableContext>
                    )}
                  </div>
                </div>
              </div>
            </div>
          </DndContext>
        </StandardModal>
      </ModalContext.Provider>
    </TableViewEditContext.Provider>
  );
};

export default TableViewConfigModal;
