import { ClientFormUserRole } from './ClientFormUserRoles';
import { ClientFormStatus } from './ClientFormStatus';
import { FilterValue } from './TableViewFilters';
import { RiskRating } from '../utils/RiskUtils';
import { PeriodicReviewStatus } from './PeriodicReview';
import { DistributionStatus, DistributionStatusFilter } from './Distribution';

export const mainViewId = '00000000-0000-0000-0000-000000000000';
export const newViewId = 'NewView';
export const freezeLineId = 'FreezeLine';
export const noDataId = false as const;

export type TableViewCreateRequest = {
  clientModuleId: string;
  templateModuleSectionId: string | null;
  name: string;
  clientId: string;
  userId?: string;
  isPersonalDefault: boolean;
  isOrganisationDefault: boolean;
  columnConfigurations: TableViewColumnConfigurationRequest[];
};

export type TableViewUpdateRequest = {
  name: string;
  userId?: string;
  isPersonalDefault: boolean;
  isOrganisationDefault: boolean;
  columnConfigurations: TableViewColumnConfigurationRequest[];
};

export type TableViewColumnConfigurationRequest = { templateFormId: string } & TableViewColumnConfiguration;
export type TableViewColumnConfigurationResponse = { templateFormId: string } & TableViewColumnConfiguration;

export type TableView = {
  id: string;
  templateModuleSectionId: string | null;
  name: string;
  clientId: string;
  userId?: string;
  isDefault: boolean;
  isPersonalDefault: boolean;
  isOrganisationDefault: boolean;
  defaults: TableViewDefaultResponse[];
  isFavourite: boolean;
  columnConfigurations: Record<string, TableViewColumnConfiguration> | null;
};

export type TableViewResponse = {
  id: string;
  clientModuleSectionId: string;
  name: string;
  clientId: string;
  userId?: string;
  isDefault: boolean;
  isPersonalDefault: boolean;
  isOrganisationDefault: boolean;
  defaults: TableViewDefaultResponse[];
  isFavourite: boolean;
  columnConfigurations: TableViewColumnConfigurationResponse[];
};

export type TableViewColumnConfiguration = {
  id?: string;
  enabled: boolean;
  columns: ColumnConfig[];
  sortIndex?: number;
  enableIndexNumbering: boolean;
};

export type CreateTableViewDefaultRequest = {
  tableViewId: string;
  clientModuleSectionId: string;
  clientId: string;
  userId?: string;
};

export type TableViewDefaultResponse = {
  id: string;
  userId?: string;
  tableViewId: string;
  clientId: string;
};

export enum TableViewType {
  Organisation = 0,
  User = 1,
}

export enum SortDirection {
  Ascending = 0,
  Descending = 1,
}

export const SortDirectionKeys = {
  [SortDirection.Ascending]: 'table-view:sorting.ascending',
  [SortDirection.Descending]: 'table-view:sorting.descending',
} as const;

export enum ColumnType {
  Action = 0,
  MetaData = 1,
}

export type ColumnConfig = {
  value: string;
  type: ColumnType;
  freeze?: boolean;
  filter?: FilterValue;
  sortDirection?: SortDirection;
};

export const tableViewMetadata = {
  subTitle: 'subTitle',
  version: 'version',
  dueDate: 'dueDate',
  members: 'members',
  status: 'status',
  lastModified: 'lastModified',
  createdBy: 'createdBy',
  createdOn: 'createdOn',
  effectiveDate: 'effectiveDate',
  progress: 'progress',
  risk: 'risk',
  allReferences: 'allReferences',
  allAttachments: 'allAttachments',
  owner: 'owner',
  editor: 'editor',
  viewer: 'viewer',
  approver: 'approver',
  reviewer: 'reviewer',
  periodicNextReview: 'periodicNextReview',
} as const;

type GraphQLFieldSelectorType = {
  field: string;
  subFields?: string[];
  auxillaryFields?: string[];
};

export const graphQLMetadataFields: Record<(typeof tableViewMetadata)[keyof typeof tableViewMetadata], GraphQLFieldSelectorType> = {
  subTitle: { field: 'subtitle' },
  dueDate: { field: 'dueDateUtc' },
  members: { field: 'users', subFields: ['userId', 'role', 'formSectionId', 'pbkUser { id firstName lastName }'] },
  status: {
    field: 'status',
    auxillaryFields: ['archivedUtc', 'type', 'templateForm { id version requiresApproval }'],
  },
  lastModified: { field: 'modifiedUtc' },
  createdBy: { field: 'createdBy', subFields: ['id', 'firstName', 'lastName'], auxillaryFields: ['createdById'] },
  createdOn: { field: 'createdUtc' },
  effectiveDate: { field: 'effectiveDateUtc' },
  progress: { field: 'progress' },
  risk: { field: 'risk' },
  allReferences: { field: 'references', subFields: ['id', 'subtitle'] },
  allAttachments: { field: 'attachments', subFields: ['id', 'name'] },
  owner: { field: 'owners', subFields: ['id', 'firstName', 'lastName'] },
  editor: { field: 'editors', subFields: ['id', 'firstName', 'lastName'] },
  viewer: { field: 'viewers', subFields: ['id', 'firstName', 'lastName'] },
  approver: { field: 'approvers', subFields: ['id', 'firstName', 'lastName'] },
  reviewer: { field: 'reviewers', subFields: ['id', 'firstName', 'lastName'] },
  version: { field: 'version' },
  periodicNextReview: { field: 'periodicReview', subFields: ['nextReviewStartDate'] },
};

export type TableViewMetaDataKey = keyof typeof tableViewMetadata;

export const mainViewConfig = {
  [mainViewId]: {
    enabled: true,
    enableIndexNumbering: false,
    columns: [
      { value: tableViewMetadata.subTitle, type: ColumnType.MetaData, freeze: true, sortDirection: SortDirection.Ascending },
      { value: tableViewMetadata.dueDate, type: ColumnType.MetaData },
      { value: tableViewMetadata.members, type: ColumnType.MetaData },
      { value: tableViewMetadata.status, type: ColumnType.MetaData },
      { value: tableViewMetadata.progress, type: ColumnType.MetaData },
      { value: tableViewMetadata.risk, type: ColumnType.MetaData },
    ],
  },
};

export const GQLStatusToPlatformStatus: Record<string, ClientFormStatus> = {
  NOT_STARTED: ClientFormStatus.NotStarted,
  IN_PROGRESS: ClientFormStatus.InProgress,
  SUBMITTED_FOR_VALIDATION: ClientFormStatus.SubmittedForValidation,
  SUBMITTED_FOR_APPROVAL: ClientFormStatus.SubmittedForApproval,
  COMPLETED: ClientFormStatus.Completed,
};

export const PlatformStatusToGQLStatus: Record<ClientFormStatus, string> = {
  [ClientFormStatus.NotStarted]: 'NOT_STARTED',
  [ClientFormStatus.InProgress]: 'IN_PROGRESS',
  [ClientFormStatus.SubmittedForValidation]: 'SUBMITTED_FOR_VALIDATION',
  [ClientFormStatus.SubmittedForApproval]: 'SUBMITTED_FOR_APPROVAL',
  [ClientFormStatus.Completed]: 'COMPLETED',
};

export const PlatformReviewStatusToGQLStatus: Record<PeriodicReviewStatus, string> = {
  [PeriodicReviewStatus.UnderReview]: 'UNDER_REVIEW',
  [PeriodicReviewStatus.UpcomingReview]: 'UPCOMING_REVIEW',
  [PeriodicReviewStatus.ReviewOverdue]: 'REVIEW_OVERDUE',
  [PeriodicReviewStatus.ReviewCompleted]: 'REVIEW_COMPLETED',
  [PeriodicReviewStatus.ReviewArchived]: 'REVIEW_ARCHIVED',
};

export const PlatformDistributionStatusToGQLStatus: Record<DistributionStatusFilter, string> = {
  [DistributionStatus.Active]: 'ACTIVE',
  [DistributionStatus.InActive]: 'IN_ACTIVE',
  [DistributionStatus.Completed]: 'COMPLETED',
  [DistributionStatusFilter.ActionNeededByCurrentUser]: 'ACTION_NEEDED_BY_CURRENT_USER',
};

export const PlatformClientFormUserRoleStatusToGQLStatus = {
  [ClientFormUserRole.Viewer]: 'VIEWER',
  [ClientFormUserRole.Approver]: 'APPROVER',
  [ClientFormUserRole.Owner]: 'OWNER',
  [ClientFormUserRole.Validator]: 'VALIDATOR',
  [ClientFormUserRole.Contributor]: 'CONTRIBUTOR',
} as const;

export const MetaClientFormUserRoleStatusToGQLStatus = {
  [tableViewMetadata.viewer]: PlatformClientFormUserRoleStatusToGQLStatus[ClientFormUserRole.Viewer],
  [tableViewMetadata.approver]: PlatformClientFormUserRoleStatusToGQLStatus[ClientFormUserRole.Approver],
  [tableViewMetadata.reviewer]: PlatformClientFormUserRoleStatusToGQLStatus[ClientFormUserRole.Validator],
  [tableViewMetadata.owner]: PlatformClientFormUserRoleStatusToGQLStatus[ClientFormUserRole.Owner],
  [tableViewMetadata.editor]: PlatformClientFormUserRoleStatusToGQLStatus[ClientFormUserRole.Contributor],
} as const;

export const GQLRiskToPlatformRisk: Record<string, RiskRating> = {
  NONE: RiskRating.NO_RISK,
  LOW: RiskRating.LOW_RISK,
  MEDIUM: RiskRating.MEDIUM_RISK,
  HIGH: RiskRating.HIGH_RISK,
  CRITICAL: RiskRating.CRITICAL_RISK,
};

export const PlatformRiskToGQLRisk = {
  [RiskRating.NO_RISK]: 'NONE',
  [RiskRating.LOW_RISK]: 'LOW',
  [RiskRating.MEDIUM_RISK]: 'MEDIUM',
  [RiskRating.HIGH_RISK]: 'HIGH',
  [RiskRating.CRITICAL_RISK]: 'CRITICAL',
} as const;
