import { MutableRefObject, createContext, useContext, useRef, useState } from 'react';
import { ColumnConfig } from '../../models/TableView';
import { FCWithChildren } from '../../types/FCWithChildren';
import { FilterValue, Filters } from '../../models/TableViewFilters';
import { ActionTypeNames } from '../../components/form/ActionTypes';

export type ActionColumn = {
  actionType: ActionTypeNames;
  title: string | JSX.Element[];
};

export type TableViewFilterContextProps = {
  filters: Filters;
  setFilters: (columnConfig: ColumnConfig, selectedTemplateId: string, filter: FilterValue | undefined) => void;
  clearFilter: (columnConfig: ColumnConfig, selectedTemplateId: string) => void;
  getInternalChangeRef: (columnConfig: ColumnConfig, selectedTemplateId: string) => MutableRefObject<boolean>;
  searchTerm: string | undefined;
  setSearchTerm: (value: string | undefined | ((prev: string | undefined) => string | undefined)) => void;
  actionTypes: Record<string, ActionColumn>;
  setActionsTypes: (value: Record<string, ActionColumn> | ((prev: Record<string, ActionColumn>) => Record<string, ActionColumn>)) => void;
};

// eslint-disable-next-line @typescript-eslint/no-non-null-assertion
export const TableViewFilterContext = createContext<TableViewFilterContextProps>(null!);
export const useTableViewFilters = (): TableViewFilterContextProps => useContext(TableViewFilterContext);

export const TableViewFilterProvider: FCWithChildren = (props) => {
  const { children } = props;
  const [filters, setFilters] = useState<Filters>({});
  const [searchTerm, setSearchTerm] = useState<string | undefined>(undefined);

  const internalChangeRefs = useRef<Record<string, MutableRefObject<boolean>>>({});
  const [actionTypes, setActionsTypes] = useState<Record<string, ActionColumn>>({});
  const getInternalChangeRef = (columnConfig: ColumnConfig, selectedTemplateId: string) => {
    const key = `${selectedTemplateId}-${columnConfig.value}`;
    if (!internalChangeRefs.current[key]) {
      internalChangeRefs.current[key] = { current: false };
    }
    return internalChangeRefs.current[key];
  };

  const setFiltersInternal = (columnConfig: ColumnConfig, selectedTemplateId: string, filter: FilterValue | undefined) => {
    if (filter === undefined) {
      clearFilter(columnConfig, selectedTemplateId);
      return;
    }
    const ref = getInternalChangeRef(columnConfig, selectedTemplateId);
    ref.current = true;

    setFilters((prevFilters) => ({
      ...prevFilters,
      [selectedTemplateId]: {
        ...prevFilters[selectedTemplateId],
        [columnConfig.value]: {
          columnConfig,
          filter: filter,
        },
      },
    }));
  };

  const clearFilter = (columnConfig: ColumnConfig, selectedTemplateId: string) => {
    const ref = getInternalChangeRef(columnConfig, selectedTemplateId);
    ref.current = true;
    setFilters((prevFilters) => ({
      ...prevFilters,
      [selectedTemplateId]: {
        ...prevFilters[selectedTemplateId],
        [columnConfig.value]: undefined,
      },
    }));
  };

  return (
    <TableViewFilterContext.Provider
      value={{ filters, setFilters: setFiltersInternal, clearFilter, getInternalChangeRef, actionTypes, setActionsTypes, searchTerm, setSearchTerm }}
    >
      {children}
    </TableViewFilterContext.Provider>
  );
};
